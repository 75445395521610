<template>

    <swiper-slide class="h-full w-full overflow-auto no-scrollbar" style="width: 90%">

        <div class="h-full w-full ">

            <div v-if="!loading" class="h-auto flex flex-col">

                <div class="h-auto w-full rounded-lg module p-2 flex flex-row justify-start items-center py-4">
                    <span class="text-xxs text-font-gray mr-4 font-semibold">Purchased Hours</span>
                    <span class="text-xs text-white font-semibold mr-auto">{{ totals.purchase_hours_sl | numberFormat(0) }}</span>
                    <span class="text-xxs text-font-gray mr-4 font-semibold">Sold Hours</span>
                    <span class="text-xs text-white font-semibold">{{ totals.sold_hours_sl | numberFormat(0) }}</span>
                </div>

                <div class="h-auto w-full rounded-lg module p-2 flex flex-col mt-2">

                    <div class="h-8 flex flex-row justify-start items-center">
                        <span class="text-sm font-semibold text-font-gray mr-auto">SL</span>
                        <i class="mdi mdi-filter-variant mr-2"></i>
                        <multiselect class="w-32" :multiple="false" v-model="sortBy" label="name"
                            placeholder="Select option" :options="sortOptions" :searchable="false"/>
                        <div class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer mx-1 mr-2" :class="{'bg-aux-15 text-aux':all, 'bg-filters': !all}" @click="toggleSlSelectedItemsAll()">
                            <span class="text-3xs">ALL</span>
                        </div>
                    </div>

                    <!-- {{legendColors}} -->

                    <div v-for="(el, index) in slElementsOrdered" :key="index" class="h-32 box mt-2 flex flex-col">
                        <div class="h-6 flex flex-row justify-start items-center mb-2 flex-none">
                            <div v-if="legendColors[el.id]" class="h-full w-1 rounded-full mr-2" :style="'background-color:' + legendColors[el.id].color || + 'white' + + ';'"></div>
                            <div v-else class="h-full w-1 rounded-full mr-2" :style="'background-color: gray;'"></div>
                            <span class="text-white font-semibold text-xs mr-auto"> {{ el.name }}</span>
                            <div class="h-full w-6 rounded-lg bg-aux-15 flex flex-col justify-center items-center" @click="openGraph(el)">
                                <i class="mdi mdi-chart-donut text-aux"></i>
                            </div>
                            <!-- <router-link :to="{ name: 'InterIntraExtend', params: { catId: i.name, idSL: i.id }}"
                                     class="flex items-center ml-auto">
                                <div class="header-btn  ml-0"><i class="mdi mdi-chart-donut"></i></div>
                            </router-link> -->
                            <div class="h-6 w-6 rounded-md flex flex-col justify-center items-center cursor-pointer ml-2" :class="getOpacity(el.id)" @click="toggleSlSelectedItems(el.id)">
                                <i class="mdi mdi-ray-start-arrow text-xs"></i>
                            </div>
                        </div>

                        <div class="h-6 flex flex-row justify-center items-center">

                            <div class="h-1 w-1 rounded-full shadow-md bg-orange mr-1"></div>

                            <span class="text-3xs mr-2 text-font-gray">Purchased</span>

                            <span class="text-3xs mr-2 font-semibold">{{el.hour_purchased | numberFormat(0)}}h.</span>

                            <div class="h-1 w-1 rounded-full shadow-md bg-blue mr-1"></div>

                            <span class="text-3xs mr-2 text-font-gray">Sold</span>

                            <span class="text-3xs font-semibold">{{el.hour_sold | numberFormat(0)}}h.</span>

                        </div>

                        <div v-if="loadGraph" class="flex-1 min-h-0 pb-1">

                            <!-- <ChartColCompareSmallMobile colors="purcashed" chartId="chart"
                                :data="[el.amount_purchased, el.amount_sold]"/> -->

                            <chart-line-compare
                                        chartId="salesCompare"
                                        :data="[el.amount_purchased, el.amount_sold]"
                                        :mobile="true"
                                />

                        </div>

                    </div>

                </div>

            </div>

            <div v-else class="h-screen relative module">
                <loader :loading="loading" />
            </div>

        </div>

    </swiper-slide>

</template>

<script>

import { state, actions } from '@/store';

export default {
    data() {
        return {
            filters: null,
            activeOption: [],
            totals: false,
            filter: null,
            loading: false,
            slElements: false,
            sortOptions: this.$optionsFilterInterIntra,
            sortBy: { name: 'Name', value: 'name' },
            colorsGraph:[
                ['#F7CB85','#FE694E'],
                ['#11B0EB','#6511EB']
            ],
            loadGraph:false,
            all: false
        }
    },
    methods: {

        onPanUp() {
            this.filters = false 
        },
        onPanDown() {
            this.filters = true 
        },
        add(option) {
            if (this.activeOption.length > 0) {
                let error = false
                for (let index = 0; index < this.activeOption.length; index++) {
                    if (option === this.activeOption[index]) {
                        error = true
                        this.activeOption.splice(index, 1)
                    }
                }
                if (!error) { this.activeOption.push(option) }
            }
            else { this.activeOption.push(option) }
        },
        preload() {
            this.loading = true
            state.slSelectedItems = []
        },
        load() {
            this.axios.get('inter_intra/hours_purchased_sold', {params: { ...this.params}}).then(response => {
                this.totals = response.data.data
                this.axios.get('inter_intra/sl', { params: this.params }).then(response => {
                    this.loading = false
                    this.slElements = response.data.data
                    this.$setColorsBySector(this.slElements)
                    state.slSelectedItems = []
                    setTimeout(() => {
                        this.loadGraph = true
                    }, 600);
                })
            });
        },
        openGraph(sl){
            //<router-link :to="{ name: 'InterIntraExtend', params: { catId: i.name, idSL: i.id }}"
            actions.setInterIntraSL(sl)
            this.$router.push( {name:'InterIntraMobileGraph', params: {catId: sl.name, idSL: sl.id }} )
        },
        getOpacity(k) {

            if (this.slSelectedItems.includes(k)) {
                return 'bg-aux-15 text-aux'
            } else {
                return 'bg-font-gray-15 text-font-gray'
            }

        },
        toggleSlSelectedItems(item) {


            if(!this.slSelectedItems.includes(item)){
                actions.toggleSlSelectedItems(item)
                this.$emit('nextPage')
            } else {
                actions.toggleSlSelectedItems(item)
            }
        },
        toggleSlSelectedItemsAll(){

            this.all = !this.all

            if(!this.all){

                for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
                    state.slSelectedItems = state.slSelectedItems.filter(item => item !== Object.keys(this.legendColors)[index])
                }

            } else {

                for (let index = 0; index < Object.keys(this.legendColors).length; index++) {
                    state.slSelectedItems.push(Object.keys(this.legendColors)[index])
                }

            }

            if(this.all){
                this.$emit('nextPage')
            }

        }
    },
    computed: {
        params() {
            return state.globalParams 
        },
        slElementsOrdered() {
            if (this.sortBy && this.slElements) {
                return this._.orderBy(this.slElements, [this.sortBy.value], [this.sortBy.direction]) }
            else { return this.slElements }
        },
        slSelectedItems(){
            return state.slSelectedItems
        },
        legendColors() {
            return state.slLegendColors 
        },
    },
    watch: {
        activeOption(n, o) {
            this.$emit('options', n) 
        },
        params() {
            this.load() 
        }
    },
    mounted() {
        this.preload();
        if(this.params) {
            this.load();
        }
    }
}
</script>